import React, { useState, useEffect, useRef } from 'react';

const StreamCard = ({
  streamId,
  name,
  url,
  type,
  password,
  onEditClick,
  onCopyEmailClick,
  copyCredentialsClick,
  openBrowserClick,
}) => {
  const [streamURL, setURL] = useState();
  const [streamType, setType] = useState();
  const [streamPassword, setStreamPassword] = useState();
  const OvenPlayer = useRef(window.OvenPlayer);
  let vidContainer = useRef();

  useEffect(() => {
    function initOven(oven, url, key, elId) {
      let webrtcSource = oven.generateWebrtcUrls([
        {
          host: url,
          application: 'app',
          stream: key,
          label: 'DELI',
        },
      ]);
      let player = oven.create(elId, {
        title: key,
        sources: webrtcSource,
        controls: true,
        autoStart: true,
        disableSeekUI: true,
      });

      player.on('error', function (error) {
        console.log(error);
      });
      player.on('ready', function () {
        console.log('player is ready');
      });
    }

    setURL(url);
    setType(type);
    setStreamPassword(password);
    initOven(OvenPlayer.current, url, type, vidContainer.current.id);
  }, [url, type, password]);

  return (
    <div className='stream-item'>
      {/* <img src={thumb_path} alt={name} /> */}
      <video
        width='320'
        id={name}
        controls
        ref={vidContainer}
        style={{ marginBottom: '32px' }}></video>
      <div className='stream-content'>
        <h3 className='title noselect cap'>{`${name}`}</h3>
        <button
          className='btn'
          onClick={onEditClick(
            streamId,
            streamURL,
            streamPassword,
            streamType
          )}>
          Edit Stream
        </button>
        <button
          className='btn'
          onClick={onCopyEmailClick(streamId, streamPassword)}>
          Copy to Email
        </button>
        <button
          onClick={copyCredentialsClick(streamId, streamPassword)}
          className='btn'>
          Copy to Clipboard
        </button>
        <button onClick={openBrowserClick(streamId)} className='btn'>
          Open in Browser
        </button>
        <a
          className='btn'
          href='/howto.pdf'
          target='_blank'
          rel='noreferrer noopener'
          type='button'>
          Hilfe
        </a>
      </div>
    </div>
  );
};

export default StreamCard;
