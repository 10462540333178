import React from 'react';
import DeliLogo from './DeliLogo';
import Routes from './Routes';

const Layout = ({ children }) => {
  return (
    <>
      <header>
        <div className='logo'>
          <DeliLogo />
        </div>
      </header>
      <Routes />
      <footer>
        <div className='copyright'>{`© ${new Date().getFullYear()} deli.hamburg`}</div>
      </footer>
    </>
  );
};

export default Layout;
