import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from '@reach/router';
import useAPI from '../hooks/useAPI';
import Cookies from 'universal-cookie';
import LoadingSpinner from './LoadingSpinner';
import StreamCard from './StreamCard';
import FormElement from './FormElement';

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(true);
  const [streams, setStreams] = useState([]);
  const [streamURL, setStreamURL] = useState('');
  const [streamType, setStreamType] = useState('');
  const [password, setPassword] = useState('');
  const [streamId, setStreamId] = useState('');
  const streamEditModal = useRef();
  const dismissBtn = useRef();
  const clipbaordCopy = useRef();
  const {
    getStreamDbEntries,
    setStreamDbEntries,
    isAuthenticated,
    //resetStream,
  } = useAPI();
  const cookies = new Cookies();
  const token = cookies.get('token');
  const location = window.location;

  const gettingDbStreams = async (token) => {
    setShowSpinner(true);
    return await getStreamDbEntries(token);
  };

  const isAuth = async (token) => await isAuthenticated(token);

  useEffect(() => {
    isAuth(token).then((res) => {
      if (res.isAuthenticated) {
        gettingDbStreams(token).then((res) => {
          setStreams(res);
          setShowSpinner(false);
        });
      } else {
        navigate('/');
      }
    });
    // eslint-disable-next-line
  }, []);

  const onEditClick = (streamId, streamURL, streamPwd, streamType) => (evt) => {
    setStreamURL(streamURL);
    setStreamType(streamType);
    setPassword(streamPwd);
    setStreamId(streamId);
    let modal = streamEditModal.current.parentElement;
    modal.style.zIndex = 99999;
    modal.classList.toggle('hidden');
  };

  const onCopyEmailClick = (streamId, streamPwd) => (evt) => {
    evt.preventDefault();
    let anchor = document.createElement('a');
    const url = location.href.replace('dashboard', 'stream/' + streamId);
    anchor.href =
      'mailto:mustermann@mail.de?subject=Studio Funk Broadcast Zugangsdaten&body=Hier ist dein Link:%0D%0A%0D%0A' +
      url +
      '%0D%0A%0D%0ADein Passwort lautet:%0D%0A%0D%0A' +
      streamPwd;
    anchor.click();
    anchor = null;
    location.reload(true);
  };

  const pwdGen = (evt) => {
    const generateSlicePoints = (amount, max) =>
      Array.from(new Array(amount)).map(
        (n) => Math.floor(Math.random() * max) + 1
      );

    const checkForDublicates = (data) =>
      data.every((item, index, array) => array.indexOf(item) === index);

    const generatePassword = () => {
      let sym = [
        '@',
        '$',
        '%',
        '&',
        '?',
        '-',
        '_',
        '<',
        '>',
        '*',
        '/',
        '{',
        '}',
        '(',
        ')',
        '!',
        '.',
        ':',
        '+',
        '#',
      ];
      let pwd = Math.random().toString(36).slice(-10);
      let slices = generateSlicePoints(3, 10);
      let uniqueSlices = checkForDublicates(slices);
      if (uniqueSlices) {
        let lastSlice = 0;
        return slices
          .sort()
          .map((slice) => {
            let pwdSlice = pwd.slice(lastSlice, slice + 1);
            const letterIdx = pwdSlice.search(/[a-z]/i);
            lastSlice = slice;
            if (letterIdx !== -1) {
              return pwdSlice
                .split('')
                .map((char, idx) => {
                  if (idx === letterIdx) {
                    return char.toUpperCase();
                  }
                  return char;
                })
                .join('');
            }
            return pwdSlice;
          })
          .reduce((acc, next, idx) => acc + next + sym[slices[idx]], '');
      } else {
        return generatePassword();
      }
    };
    setPassword(generatePassword());
  };

  const copyCredentialsClick = (streamId, streamPwd) => (evt) => {
    const url = location.href.replace('dashboard', 'stream/' + streamId);

    clipbaordCopy.current.value = '\n\n' + url + '\n\n' + streamPwd;
    clipbaordCopy.current.select();
    document.execCommand('copy');
  };

  const openBrowserClick = (streamId) => (evt) => {
    console.log(location.href.replace('dashboard', 'stream/'));
    const newTab = document.createElement('a');
    newTab.href =
      location.href.replace('dashboard', 'stream/') + streamId.toString();
    newTab.target = '_blank';
    newTab.rel = 'noopener';
    newTab.click();
  };

  const onSubmit = (evt) => {
    evt.preventDefault();
    const formData = new FormData(streamEditModal.current);
    dismissBtn.current.click();
    setShowSpinner(false);
    setStreamDbEntries(formData, token)
      .then((res) => {
        gettingDbStreams(token).then((res) => {
          setStreams(res);
          setShowSpinner(false);
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className='container'>
      {showSpinner ? <LoadingSpinner /> : null}
      <div id='dashboard-container' className={showSpinner ? 'hidden' : ''}>
        <textarea
          ref={clipbaordCopy}
          style={{
            position: 'absolute',
            top: '-1000px',
            left: '-1000px',
          }}></textarea>
        <div id='stream-edit-modal' className='hidden'>
          <form id='stream-edit-form' onSubmit={onSubmit} ref={streamEditModal}>
            <span
              className='dismiss-btn icon icon-clear'
              ref={dismissBtn}
              onClick={() => {
                let modal = streamEditModal.current.parentElement;
                modal.classList.toggle('hidden');
                modal.style.zIndex = -1;
                setStreamURL('');
                setPassword('');
              }}></span>
            <input id='stream-url' type='hidden' name='url' value={streamURL} />
            <FormElement
              icon='vpn_key'
              iconBtn='security'
              id='password'
              type='text'
              name='password'
              placeholder='New Password'
              autoComplete='current-password'
              parentClass='nickel'
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
              pwdGen={pwdGen}
              required
            />
            <FormElement
              icon='sim_card_alert'
              id='stream-type'
              type='text'
              name='type'
              placeholder='Stream Key'
              autoComplete='stream-type'
              parentClass='nickel'
              value={streamType}
              onChange={(evt) => setStreamType(evt.target.value)}
              required
            />
            <FormElement
              name='id'
              type='hidden'
              value={streamId}
              parentClass='hidden-before-after no-margin'
            />
            <FormElement
              id='submit'
              type='submit'
              value='Speichern'
              parentClass='hidden-before-after'
            />
          </form>
        </div>

        {streams
          ? streams.map((stream) => (
              <StreamCard
                key={stream.id}
                streamId={stream.id}
                name={stream.name}
                type={stream.type}
                url={stream.url}
                password={stream.password}
                thumb_path={stream.thumb_path}
                onEditClick={onEditClick}
                onCopyEmailClick={onCopyEmailClick}
                copyCredentialsClick={copyCredentialsClick}
                openBrowserClick={openBrowserClick}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Dashboard;
