import React from "react";

const FormElement = (props) => {
  const { icon, iconBtn, pwdGen, parentClass, ...remainingProps } = props;
  return (
    <div
      className={parentClass ? `form-element ${parentClass}` : "form-element"}
    >
      {icon && <span className={`icon icon-${icon}`}></span>}
      <input {...remainingProps} />
      {iconBtn && (
        <span
          onClick={pwdGen}
          className={`icon icon-${iconBtn} icon_btn`}
        ></span>
      )}
    </div>
  );
};

export default FormElement;
