import React, { useState, useRef } from 'react';
import { useNavigate } from '@reach/router';
import Cookies from 'universal-cookie';
import useAPI from '../hooks/useAPI';
import FormElement from './FormElement';

const AdminLogin = (props) => {
  const navigate = useNavigate();
  const { adminLogin } = useAPI();
  const loginForm = useRef();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const onSubmit = (evt) => {
    evt.preventDefault();
    setError('');
    const formData = new FormData(loginForm.current);
    adminLogin(formData)
      .then((res) => {
        if (res.isAuthenticated) {
          const cookies = new Cookies();
          cookies.set('token', res.token);
          navigate('/dashboard');
        }
        setError('Wrong Credentials! Please try agian.');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form id='login-form' onSubmit={onSubmit} ref={loginForm}>
      <h2 className='title'>Login</h2>
      {error ? (
        <div className='error-message-container'>
          <p>{error}</p>
        </div>
      ) : null}
      <FormElement
        icon='person'
        id='username'
        type='text'
        name='username'
        placeholder='Username'
        autoComplete='username'
        parentClass='nickel'
        value={username}
        onChange={(evt) => setUsername(evt.target.value)}
        required
      />
      <FormElement
        icon='vpn_key'
        id='password'
        type='password'
        name='password'
        placeholder='Password'
        autoComplete='current-password'
        parentClass='nickel'
        value={password}
        onChange={(evt) => setPassword(evt.target.value)}
        required
      />
      <FormElement
        id='submit'
        type='submit'
        value='Login'
        parentClass='hidden-before-after'
      />
    </form>
  );
};

export default AdminLogin;
