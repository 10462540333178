import React from "react";
import AdminLogin from "./AdminLogin";
import Dashboard from "./Dashboard";
import StreamView from "./StreamView";
import { Router } from "@reach/router";

const NotFound = () => <div>Sorry, nothing here.</div>;

//const StreamsView = (props) => <div>Stream View {props.streamId}</div>;

const Routes = (props) => {
  return (
    <main>
      <Router>
        <AdminLogin path="/" />
        <Dashboard path="/dashboard" />
        <StreamView path="stream/:streamId" />
        <NotFound default />
      </Router>
    </main>
  );
};

export default Routes;
